<template>
    <div class="container2">
        <header class="header sticky-bar" style="background-color: #37663c; color:white !important;">
            <div class="container">
                <div class="pt-10 pb-10 topNav" ref="topNav">
                    <div class="row">
                        <div class="col-7 col-md-3">
                            <a href="/" style="color:white;">
                                <div style="display:inline-block; align: center;vertical-align: middle;">
                                    <img alt="Traincollector" src="/rangeerspoor.png" width="30" />
                                </div>
                                <div style="display:inline-block;vertical-align: middle; font-size:22px;" class="pl-10">
                                    {{ $t('global.rs') }}
                                </div>
                            </a>

                        </div>
                        <div class="d-none d-xl-block col-md-7 ">
                            <nav class="nav-main-menu d-none d-xl-block">
                                <ul class="main-menu">
                                    <li><router-link :to="{name: 'home'}">{{ $t('global.home') }}</router-link></li>
                                    <li><router-link :to="{name: 'items'}">{{ $t('global.items') }}</router-link></li>
                                    <li><router-link :to="{name: 'inventory'}">{{ $t('global.inventory') }}</router-link></li>
                                    <li><router-link :to="{name: 'wishlists'}">{{ $t('global.wishlists') }}</router-link></li>
                                    <li><router-link :to="{name: 'listings'}">{{ $t('global.ads') }}</router-link></li>
                                    <!--<li v-if="isLoggedIn">
                                        <router-link style="background-color: #ff7900; padding: 5px 10px; color: white;"
                                                     :to="{name: 'createListing'}"
                                                     v-on:click="menuOpen=false;">
                                            + {{ $t('global.ad') }}
                                        </router-link>

                                    </li>-->
                                    <li v-if="!isLoggedIn">
                                        <router-link style="background-color: #ff7900; padding: 5px 10px; color: white;"
                                                     :to="{name: 'register'}"
                                                     class="registerBtn  mr-10"
                                                     href="/registreren">
                                            <span><font-awesome-icon icon="fa-solid fa-user" class="mr-5" />{{ $t('global.newAccount') }}</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="col-5 col-md-2 pt-5" style="text-align:right">
                            <div>
                                <router-link v-if="!isLoggedIn" :to="{name: 'register'}"
                                             class=" d-sm-none registerIcon mr-10"
                                             href="/registreren">
                                    <font-awesome-icon icon="fa-solid fa-user" size="xl" class="mr-5" />
                                </router-link>

                                <span id="searchMenuBtn" v-on:click="openSearch()" class="mr-10">
                                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" size="xl" />
                                </span>
                                <span id="settingsMenuBtn" v-on:click="settingsMenuOpen = !settingsMenuOpen"
                                      class="mr-10">
                                    <font-awesome-icon :icon="['fas', 'cog']" size="xl" />
                                </span>
                                <router-link v-if="isLoggedIn" :to="{name: 'dashboard'}" style="color: white !important "
                                             class="mr-10" v-on:click="menuOpen=false;">
                                    <font-awesome-icon icon="fa-solid fa-user" size="xl" />
                                </router-link>
                                <span id="navMenuBtn" v-on:click="menuOpen = !menuOpen">
                                    <font-awesome-icon ref="navMenuBtn" icon="fa-solid fa-bars" size="xl" />
                                </span>
                            </div>
                            <div class=" dropdown-settings"
                                 id="settingsMenu" style=""
                                 v-if="settingsMenuOpen"
                                 ref="settingsMenu" :style="{right:menuPosition + 'px'}">
                                <div class="mb-10">
                                    <font-awesome-icon :icon="['fas', 'language']" class="mr-5" />{{ $t('global.language') }}:
                                </div>
                                <ul class="settingsOptions">
                                    <li v-for="lang in languages" v-bind:key="lang" v-on:click="setLanguage(lang)">
                                        <img class="flag mr-5" :src="'/flag-' +lang+ '.svg'" />

                                        <span :class="$i18n.locale == lang ? 'bold' : ''">{{ $t('languages.' + lang) }}</span>
                                    </li>
                                </ul>
                                <hr />
                                <div class="mb-10">
                                    <font-awesome-icon :icon="['fas', 'circle-half-stroke']" class="mr-5" />{{ $t('global.theme.title') }}:
                                </div>
                                <ul class="settingsOptions">
                                    <li>
                                        <label>
                                            <input type="radio" v-model="themePreference" value="system" @change="setColorScheme" />
                                            {{ $t('global.theme.system') }}
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="radio" v-model="themePreference" value="light" @change="setColorScheme" />
                                            {{ $t('global.theme.light') }}
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="radio" v-model="themePreference" value="dark" @change="setColorScheme" />
                                            {{ $t('global.theme.dark') }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div ref="navMenu">
                                <div class="dropdown-account" style="" v-if="menuOpen" id="navMenu" :style="{right:menuPosition + 'px'}">
                                    <ul>
                                        <li><router-link :to="{name: 'home'}" v-on:click="menuOpen=false;">{{ $t('global.home') }}</router-link></li>
                                        <!--<li><router-link :to="{name: 'listings'}" v-on:click="menuOpen=false;">{{ $t('global.ads') }}</router-link></li>-->
                                        <!--<li v-if="isLoggedIn"><router-link :to="{name: 'createListing'}" v-on:click="menuOpen=false;">{{ $t('global.newAd') }}</router-link></li>-->

                                        <li><router-link :to="{name: 'items'}" v-on:click="menuOpen=false;">{{ $t('global.items') }}</router-link></li>
                                        <li><router-link :to="{name: 'wishlists'}" v-on:click="menuOpen=false;">{{ $t('global.wishlists') }}</router-link></li>
                                        <li><router-link :to="{name: 'inventory'}" v-on:click="menuOpen=false;">{{ $t('global.inventory') }}</router-link></li>
                                        <!--<li v-if="isLoggedIn"><router-link :to="{name: 'recommendations'}" v-on:click="menuOpen=false;">{{ $t('global.recommendations') }}</router-link></li>-->

                                        <li v-if="isLoggedIn"><router-link :to="{name: 'messages'}" v-on:click="menuOpen=false;">{{ $t('global.messages') }}</router-link></li>

                                        <li v-if="isLoggedIn" class="border-top pt-10"><router-link :to="{name: 'logout'}" v-on:click="menuOpen=false;">{{ $t('global.logout') }}</router-link></li>
                                        <li v-if="!isLoggedIn" class="border-top pt-10"><router-link :to="{name: 'login'}" v-on:click="menuOpen=false;">{{ $t('global.login') }}</router-link></li>
                                        <li v-if="!isLoggedIn" class=""><router-link :to="{name: 'register'}" v-on:click="menuOpen=false;">{{ $t('global.register') }}</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div class="d-block d-sm-none mt-50" v-if="$route.name == 'home'">

            <ul class="navContainer ">

                <!--<li><router-link :to="{name: 'home'}"><font-awesome-icon icon="list" class="mr-5" />{{ $t('global.home') }}</router-link></li>-->
                <li><router-link :to="{name: 'items'}"><font-awesome-icon icon="table-list" class="mr-5" />{{ $t('global.items') }}</router-link></li>
                <li><router-link :to="{name: 'inventory'}"><font-awesome-icon icon="fa-list-check" class="mr-5" />{{ $t('global.inventory') }}</router-link></li>
                <li><router-link :to="{name: 'wishlists'}"><font-awesome-icon icon="fa-heart" class="mr-5" />{{ $t('global.wishlists') }}</router-link></li>
                <li><router-link :to="{name: 'listings'}"><font-awesome-icon icon="fa-grip-vertical" class="mr-5" />{{ $t('global.ads') }}</router-link></li>
                <li><router-link :to="{name: 'favorites'}"><font-awesome-icon icon="fa-star" class="mr-5" />{{ $t('global.favorites') }}</router-link></li>
            </ul>
        </div>

        <main :class="$route.name + ' main mb-10'">
            <div class="section-box" v-if="route != ''">
                <div class="breadcrumbs-div">
                    <div class="container">
                        <ul class="breadcrumb">
                            <li>
                                <router-link class="color-gray-1000" :to="{name:'home'}">{{ $t('global.home') }}</router-link>
                            </li>
                            <li class="ml-10 mr-10">
                                &raquo;

                            </li>
                            <li v-if="route != ''">
                                <a href="#" class="color-gray-1000"> {{$t(route)}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="headerSearch"
                 id="searchMenu"
                 style=""
                 v-if="searchMenuOpen">
                <Search ref="searchComponent"
                        @SearchSubmitted="SearchSubmitted"
                        :showCategories="false" />

                <div v-if="items != null">
                    <div v-for="item in items"
                         v-bind:key="item.id"
                         class="row mb-5">

                        <div class="col-3 col-md-2">
                            <img class="itemImage"
                                 src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                 :style="'background-image: url('+ item.previewUrl + ');'"
                                 style="width:80%;" />
                        </div>

                        <div class="col-9 ">
                            <router-link :to="{ name:'item', params: { itemKey: item.key} }"
                                         class="color-brand-3 font-xs-bold">
                                <b>{{item.brand}} {{item.code}}</b>
                                <div v-if="item.titles !== undefined && this.$i18n.locale.toUpperCase() in item.titles">
                                    {{item.titles[this.$i18n.locale.toUpperCase()].substring(0,42)}}
                                    <!--<span v-if="item.description.length > 40">...</span>-->
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <router-link v-if="$refs.searchComponent!= null && $refs.searchComponent.query != ''"
                             class="searchMore btn btn-buy"
                             :to="{name: 'items', params: { categoryId: ''},
                             query: {SearchQuery:  $refs.searchComponent.query}}">
                    Search in items
                </router-link>
            </div>
            <section class="mt-15">
                <div class="container">
                    <router-view ref="routerview" @SetTitle="SetTitle">
                    </router-view>
                </div>
            </section>
        </main>

        <footer class="footer">
            <div class="footer-1">
                <div class="container">
                    <div class="row g-0">
                        <div class="col-3 text-center mt-10 mb-10">
                            <a href="aboutus">
                                <font-awesome-icon class="mr-5" :icon="['fas', 'circle-info']" />    About us
                            </a>
                        </div>
                        <div class="col-3 text-center mt-10 mb-10 ">
                            <a href="contact">
                                <font-awesome-icon class="mr-5" :icon="['fas', 'at']" /> Contact
                            </a>
                        </div>
                        <div class="col-3 text-center mt-10 mb-10">
                            <a href="cookies">
                                <font-awesome-icon class="mr-5" :icon="['fas', 'cookie-bite']" />Cookies
                            </a>
                        </div>
                        <div class="col-3 text-center mt-10 mb-10">
                            <a href="privacy">
                                <font-awesome-icon class="mr-5" :icon="['fas', 'shield-halved']" />Privacy
                            </a>
                        </div>
                        <div class="col-3 text-center mt-10 mb-10">
                            <a href="disclaimer">
                                <font-awesome-icon class="mr-5" :icon="['fas', 'gavel']" />Disclaimer
                            </a>
                        </div>
                        <div class="col-3 text-center mt-10 mb-10">
                            <a href="sitemap">
                                <font-awesome-icon class="mr-5" :icon="['fas', 'sitemap']" />Sitemap
                            </a>
                        </div>
                        <div class="col-3 text-center mt-10 mb-10"></div>

                        <div class="col-3 text-center mt-10 mb-10">
                            <a class="" v-on:click="shareModalOpen = true">
                                <font-awesome-icon class="mr-5" :icon="['fas', 'share-nodes']" /> Share
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


        <div v-if="showCookieNotice" class="cookie-notice">
            <p>
                We use cookies, including cookies for advertising purposes via Google AdSense. By continuing to use this website, you agree to our use of cookies.
                <a href="/cookies" class="cookie-link">Learn more</a>.
            </p>
            <button class="close-btn" @click="closeNotice">Got it</button>
        </div>

        <div :class="{'modal-bottom fade show': shareModalOpen}" v-if="shareModalOpen"
             id="ModalFiltersForm" tabindex="-1" style="display: block;" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-lg ">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h1>Share</h1>
                        <a class="btn-close" @click="shareModalOpen = false"><font-awesome-icon icon="fa-solid fa-xmark" /></a>
                    </div>
                    <div class="modal-body">
                        <ul class="share-list">
                            <li>
                                <a @click="copyToClipboard" title="">
                                    <font-awesome-icon :icon="['fas', 'copy']" v-if="share.showCopyCopy" />
                                    <font-awesome-icon :icon="['fas', 'check']" v-if="share.showCopyCheck" />
                                    Copy to Clipboard
                                </a>
                            </li>
                            <li>
                                <a :href="'https://wa.me/?text=' + encodeURIComponent(shareText + ' ' + shareUrl)"
                                   target="_blank"
                                   title="Share on WhatsApp">
                                    <font-awesome-icon icon="fa-brands fa-whatsapp" /> Share on WhatsApp
                                </a>
                            </li>
                            <li>
                                <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(shareUrl)"
                                   target="_blank"
                                   title="Share on Facebook">
                                    <font-awesome-icon :icon="['fab', 'facebook']" /> Share on Facebook
                                </a>
                            </li>
                            <li>
                                <a :href="'https://twitter.com/intent/tweet?text=' + encodeURIComponent(shareText) + '&url=' + encodeURIComponent(shareUrl)"
                                   target="_blank"
                                   title="Share on Twitter">
                                    <font-awesome-icon :icon="['fab', 'x-twitter']" /> Share on X
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- backdrop -->
        <div v-if="searchMenuOpen || shareModalOpen" class="modal-backdrop fade show"></div>
    </div>

</template>


<script>
    import { UserClient } from '@/httpClients/userClient.js'
    import Search from '@/components/Search.vue';
    import { ItemClient } from '@/httpClients/itemClient';


    export default {
        name: 'wishlist',
        components: {
            Search,
        },
        data() {
            return {
                menuOpen: false,
                settingsMenuOpen: false,
                searchMenuOpen: false,
                route: '',
                user: null,
                isLoggedIn: false,
                menuPosition: 0,
                theme: 'light',
                languages: ["nl", "de", "en"],
                items: null,
                showCookieNotice: false,
                shareUrl: null,
                shareText: null,
                shareModalOpen: false,
                share: {
                    showCopyCopy: true,
                    showCopyCheck: false
                },
                themePreference: localStorage.getItem('ThemePreference') || 'system'
            }
        },
        watch: {
            $route() {
                this.$setRoutingName(this.$router.options.history.state.back, this.GetBackName(this.$router.options.history.state.back))
                this.route = this.GetName();

                if (typeof (this.route) != "undefined" && this.route != '' && this.$route.name != 'notfound') {
                    document.title = "Traincollector.net - " + this.route;
                } else {
                    document.title = "Traincollector.net";
                }
                this.searchMenuOpen = false;
            },
            menuOpen() {
                //const left = this.$refs.topNav.getBoundingClientRect().right
                //var totalWidth = window.innerWidth;
                //var offset = totalWidth - left;
                //this.menuPosition = offset;
            }

        },
        methods: {
            async openSearch() {
                this.searchMenuOpen = !this.searchMenuOpen;
                await new Promise(r => setTimeout(r, 500));

                if (!this.searchMenuOpen) {
                    return;
                }
                this.$refs.searchComponent.setFocus();
            },
            async SearchSubmitted() {
                var query = this.$refs.searchComponent.query
                var result = await ItemClient.Search("", 1, query, 8);
                this.items = result.data;
            },
            setLanguage: function (lang) {
                this.$i18n.locale = lang;
                localStorage.setItem("lang", lang);
            },
            async loadUser() {
                try {
                    this.isLoggedIn = await UserClient.IsLoggedIn();
                    return true;
                } catch (ex) {
                    return false;
                }
            },
            GetBackName: function (route) {
                if (route == null) {
                    return;
                }
                var split1 = route.split('?');
                var split = split1[0].split('/');
                switch (split[1]) {
                    case "item": return "item";
                    default: return split[1];
                }
            },
            isDescendant(parent, child) {
                var node = child.parentNode;

                while (node != null) {
                    if (node == parent) {
                        return true;
                    }
                    node = node.parentNode;
                }
                return false;
            },
            GetName: function () {
                switch (this.$router.currentRoute.value.name) {
                    case "home": return "";
                    default: return this.$i18n.t('global.' + this.$router.currentRoute.value.name);

                }
            },
            async SetTitle(bla) {
                this.route = bla;
                document.title = "Traincollector.net - " + bla;
            },
            closeNotice() {
                localStorage.setItem('cookieConsent', 'true');
                this.showCookieNotice = false;
            },
            async copyToClipboard() {
                await navigator.clipboard.writeText(this.shareUrl);
                this.share.showCopyCopy = false;
                this.share.showCopyCheck = true;

                await new Promise(r => setTimeout(r, 5000));

                this.share.showCopyCopy = true;
                this.share.showCopyCheck = false;
            },
            setColorScheme() {
                localStorage.setItem('ThemePreference', this.themePreference);

                if (this.themePreference == "system") {
                    this.theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ?
                        "dark" : "light";
                } else {
                    this.theme = this.themePreference;
                }

                if (this.theme == "dark") {
                    document.documentElement.setAttribute('data-theme', 'dark');
                }
                else {
                    document.documentElement.setAttribute('data-theme', 'light');
                }
            }
        },
        beforeRouteEnter(_, from, next) {
            next(vm => {
                if (typeof (from.name) === 'undefined') {
                    vm.prevRoute.displayName = "vorige pagina";
                    vm.prevRoute.fullPath = vm.$router.options.history.state.back;
                    return;
                }
            })
        },
        mounted() {
            this.showCookieNotice = !localStorage.getItem('cookieConsent');
            this.setColorScheme()
            this.loadUser();

            this.shareUrl = window.location.href;
            this.shareText = "";

            document.addEventListener("click", (e) => {
                const parent = document.getElementById("navMenu");
                const parent2 = document.getElementById("navMenuBtn");

                var isDescendant = this.isDescendant(parent, e.target);
                var isDescendant2 = this.isDescendant(parent2, e.target);

                if (isDescendant2 || isDescendant) {
                    return;
                }
                this.menuOpen = false;
            });

            document.addEventListener("click", (e) => {
                const parent = document.getElementById("settingsMenu");
                const parent2 = document.getElementById("settingsMenuBtn");

                var isDescendant = this.isDescendant(parent, e.target);
                var isDescendant2 = this.isDescendant(parent2, e.target);
                if (isDescendant2 || isDescendant) {
                    return;
                }
                this.settingsMenuOpen = false;
            });
            document.addEventListener("click", (e) => {
                const parent = document.getElementById("searchMenu");
                const parent2 = document.getElementById("searchMenuBtn");

                var isDescendant = this.isDescendant(parent, e.target);
                var isDescendant2 = this.isDescendant(parent2, e.target);
                if (isDescendant2 || isDescendant) {
                    return;
                }
                this.searchMenuOpen = false;
            });

        }
    }
</script>

<style scoped>
    .footer {
        margin-top: auto;
    }
</style>

<style>
    @import '@/css/style3.css';
    @import '@/css/style2.css';
    @import '@/css/light.css';

    .content {
        border: 1px solid #dadada;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 10px;
    }

    .dropdown-settings {
        text-align: left;
    }

    .flag {
        width: 20px;
        vertical-align: middle;
    }

    .dropdown-settings ul li {
        margin-bottom: 10px;
    }

    .registerBtn {
        background-color: rgb(255, 121, 0);
        padding: 5px 10px;
        font-size: 1em;
        color: white;
        font-weight: bold;
        text-align: center;
        border-radius: 3px;
    }

    .registerIcon {
        color: rgb(255, 121, 0);
    }

    .navContainer li {
        background-color: white;
        padding: 5px 20px 5px 20px;
        border-radius: 4px;
        text-align: center;
        float: left;
        margin-right: 10px;
    }

    .navContainer {
        overflow: scroll;
        background-color: #698C6C;
        width: 100%;
        white-space: nowrap;
        overflow-x: scroll;
        display: inline-flex;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
    }

        .navContainer::-webkit-scrollbar {
            display: none;
        }

    .bold {
        font-weight: bold;
    }

    .headerSearch {
        padding: 10px;
        width: 100%;
        position: absolute;
        top: 101px;
        z-index: 1041;
    }

    .searchMore {
        width: 100%;
        display: block;
    }

    .cookie-notice {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #f8f8f8;
        color: #333;
        padding: 1em;
        text-align: center;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
        z-index: 1000;
    }

        .cookie-notice p {
            margin: 0;
            font-size: 0.9em;
        }

    .cookie-link {
        color: #007bff;
        text-decoration: none;
    }

        .cookie-link:hover {
            text-decoration: underline;
        }

    .close-btn {
        display: inline-block;
        margin-top: 0.5em;
        padding: 0.5em 1em;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.9em;
    }

        .close-btn:hover {
            background-color: #0056b3;
        }

    @media (max-width: 768px) {
        .cookie-notice {
            font-size: 0.85em;
            padding: 1em;
        }
    }

    .shareBtn {
        display: block;
        float: left;
        border: 1px solid;
        padding: 5px;
        margin: 5px;
        border-radius: 3px;
    }

    .share-list > li {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .settingsOptions > li{
        padding-left:5px;
    }
    input[type="radio" i] {
        margin-left: 0px !important;
    }
</style>